<template>

  <v-bottom-navigation
    id="bottom-navigation"
    v-model="bnav"
    absolute
    active-class=""
    class="position-absolute position-bottom "
    color="primary"
    grow
    mode="shift"
  >
    <v-btn small
           text height="100%!important"
           value="home"
           to="/home"
    >
      <span class="d-sm-inline d-none">Nouveaute</span>

      <v-icon
        :size="icons_size"
        class=""
      >
        {{ icons.mdiHomeOutline }}
      </v-icon>
    </v-btn>

    <v-btn
      text small height="100%!important"
      to="/social"
    >
      <span class="d-sm-inline d-none">Communaute</span>

      <v-icon
        :size="icons_size">
        {{ icons.mdiAccountGroup }}
      </v-icon>
    </v-btn>

    <!--    <v-btn small
            text
          to="/gallery"
        >
          <span class="d-sm-inline d-none">Galerie</span>

          <v-icon
            :size="icons_size">
            {{ icons.mdiImageOutline }}
          </v-icon>
        </v-btn>-->
    <v-dialog max-width="500" v-model="creator">
      <v-card>
        <v-card-title>
          Createur
          <v-spacer></v-spacer>
          <v-btn @click="creator = false" icon>
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card outlined class="text-center pt-3 pb-3" block stacked x-large :to="'/comicbook/form'">
            <v-icon
              :size="icons_size">
              {{ icons.mdiBookOpenPageVariantOutline }}
            </v-icon>
            <h3 class="text-center"> Nouvelle serie</h3>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-card outlined class="text-center pt-3 pb-3" block stacked x-large :to="'/chapter/form'">
            <v-icon
              :size="icons_size">
              {{ icons.mdiBookOpenPageVariantOutline }}
            </v-icon>
            <h3 class="text-center">
              One Shot</h3>
          </v-card>
        </v-card-text>

        <v-card-text>
        <v-card outlined class="text-center pt-3 pb-3" block stacked x-large :to="'/social'">
          <v-icon
            :size="icons_size">
            {{ icons.mdiImageOutline }}
          </v-icon>
          <h3 class="text-center">
            Travaux en cours</h3>
          <v-card-text>
            Partagez vos travaux en cours, croquis et autres activites avec la communaute
          </v-card-text>
        </v-card>
        </v-card-text>
        <v-btn
          class="mt-6"
          block
          :to="'/creator/dashboard'"
        >   Allez a mon Espace Createur
        </v-btn>
        <!--    <v-card-text>
           <v-card outlined class="text-center pt-3 pb-3" block stacked x-large :to="'/illustration/form'">
             <v-icon
               :size="icons_size">
               {{ icons.mdiImageOutline }}
             </v-icon>
             <h3 class="text-center">
               Illustration</h3>
           </v-card>
         </v-card-text>
                <v-btn>Light Novel</v-btn>-->
      </v-card>
    </v-dialog>
    <v-btn small
           text height="100%!important"
           @click="creator = true"
    >
      <span class="d-sm-inline d-none">Createur</span>
      <v-icon
        :size="icons_size">
        {{ icons.mdiPlus }}
      </v-icon>
    </v-btn>

    <v-btn
      text small height="100%!important"
      to="/catalog"
    >
      <span class="d-sm-inline d-none">Catalogue</span>
      <v-icon
        :size="icons_size">
        {{ icons.mdiBookOpenPageVariantOutline }}
      </v-icon>
    </v-btn>

    <v-btn
      color="deep-purple accent-4"
      text small height="100%!important"
      :to="user.id ? '/profile/'+user.id : '/auth/login'"
    >
      <span class="d-sm-inline d-none">Mon compte</span>
      <v-icon
        :size="icons_size">
        {{ icons.mdiAccountOutline }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>

</template>

<script>
import {
  mdiAccountGroup,
  mdiBellOutline,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiCogOutline, mdiCreation, mdiEyeOffOutline,
  mdiEyeOutline, mdiGamepadCircleOutline,
  mdiPlusOutline, mdiImageOutline, mdiClose, mdiPlus,
  mdiDraw, mdiImage, mdiHomeOutline, mdiAlignVerticalBottom, mdiBookOpenPageVariantOutline,

} from '@mdi/js'
import {Drequest} from '@/plugins/Drequest'

export default {
  name: 'BottomNavigation',
  data: () => ({
    bnav: 'home',
    icons_size: 30,
    creator: false,
    icons: {
      mdiBellOutline,
      mdiAccountOutline,
      mdiEyeOutline,
      mdiCogOutline,
      mdiCreation,
      mdiAccountGroup,
      mdiImageOutline,
      mdiHomeOutline,
      mdiAccountGroupOutline,
      mdiDraw, mdiPlus,
      mdiPlusOutline, mdiClose,
      mdiGamepadCircleOutline,
      mdiEyeOffOutline, mdiAlignVerticalBottom, mdiBookOpenPageVariantOutline,
    },
    height: window.innerHeight,
    user: {},
  }),
  mounted() {
    this.user = Drequest.getUser()
    if (this.user.id) {
      this.init()
    }
  },
  methods: {
    init() {
    }
  }

}
</script>

<style scoped>

</style>
