<script setup>

export default {
  props: {
    user: Object,
    size: {
      type: Number,
      default() {
        return 150
      }
    },
    active: {
      type: Boolean,
      default() {
        return true
      }
    },
  },
  data() {
    return {}
  },
}
</script>

<template>
  <v-avatar
    :size="size"
    :class="active? 'ma-1 av-active':'ma-1'"
    @click="$emit('changeAvatar')"
  >
    <img
      v-if="user.avatar"
      alt="Avatar"
      contain
      :src="require(`@/assets/images/avatars/${user.avatar}`)"
    />
    <img
      v-else-if="user.profile"
      alt="Avatar"
      contain
      :src="user.profile"
    />
    <img v-else
      alt="Avatar"
      contain
      :src="require(`@/assets/images/avatars/logo.png`)"
    />
  </v-avatar>
</template>

<style scoped lang="scss">

.av-active {
  border: 3px solid var(--v-primary-base);
}

</style>
