<template>
  <v-card :loading="loader.loading" elevation="0" class="pa-0 pb-3">

    <v-sheet v-if="!loader.loading && postcommentll.listEntity.length === 0"
             elevation="0"
             max-width="600"
             rounded="lg"
             width="100%"
             class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{ icons.mdiCommentOutline }}
      </v-icon>

      <h2 class="text-h5 mb-6">Aucun element trouve</h2>
    </v-sheet>
      <template v-else >
<!--        <p v-if="postcommentll.nb_element > 3 && quick_comment">
          Lire les {{ postcommentll.nb_element }}
          commentaires
        </p>-->
        <v-list  class="pb-12" dense>
          <template v-for="(item, index) in postcommentll.listEntity">
            <v-divider
              :key="index"
            ></v-divider>
            <comment-item-component
              :key="item.id"
              :index="index"
              :item="item"
              :user="user"
              :commentresponse="commentresponse"
              @reply="(comment)=> postcomment = comment"
              @delete="deleteComment"
            ></comment-item-component>

          </template>
        </v-list>

        <v-btn block small v-if="postcommentll.remain"  @click="showMore()">
          Voir plus de commentaires
        </v-btn>
      </template>

    <comment-form-component  class="sticky-bottom-0" v-if="!quick_comment"
      :answerto="postcomment"
      :post_id="post_id"
      :user="user"
      @sent="newcomment"
      @cancelReply="()=> postcomment = {}"
      @initlogin="()=>$emit('initlogin')"
    ></comment-form-component>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiCommentAlertOutline,
} from '@mdi/js'
import {computed, ref, watch} from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import CommentFormComponent from '@/components/CommentFormComponent'
import CommentResponseListComponent from '@/components/CommentResponseListComponent'
import KolaToggleComponent from '@/components/KolaToggleComponent'
import CommentItemsComponent from "@/components/CommentItemsComponent.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";

export default {
  components: {
    CommentItemComponent,
    CommentItemsComponent,
    KolaToggleComponent,
    CommentResponseListComponent,
    CommentFormComponent
  },
  props: {
    post_id: Number|String,
    quick_comment: Boolean|Number,
    limit: {
      type: Number|String,
      default: () => 6,
    },
    nbcomment: {
      type: Number|String,
      default: () => 1,
    },
    user: {
      type: Object,
      default: () => {

      },
    },
    loadreply: {
      type: Boolean|Number,
      default: () => false,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const items = ref([])
    const postcommentll = ref({ listEntity: [] })
    const postcomment = ref({})
    const commentresponse = ref({})
    const loader = ref({loading:false})
    const init = (next) => {
      loader.value.loading = true
      Drequest.api(`lazyloading.postcomment?dfilters=on&post.id:eq=${props.post_id}`)
        .param({
          next: next,
          'parent_id:isNull': 1,
          'user_id': props.user.id,
          per_page: props.limit,
        })
        .get(response => {
          console.log(response)
          loader.value.loading = false

          if (next >= 2) {
            postcommentll.value.listEntity = [...postcommentll.value.listEntity, ...response.listEntity]
            postcommentll.value.next = response.next
            postcommentll.value.remain = response.remain
          } else
            postcommentll.value = response
        })
    }

    if (props.nbcomment > 0) {
      init(1)
    }

    const showMore = ()=>{
      init(postcommentll.value.next)
    }

    const newcomment = response => {
      console.log(response)
      if (!response.postcomment.parent_id) {
        postcommentll.value.listEntity.push(response.postcomment)
      } else {
        postcomment.value.nbreply += 1
        commentresponse.value = response.postcomment
      }
      postcomment.value ={}
    }
    const deleteComment = (item, index) => {
      if (!confirm('Ce commentaire va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`delete.postcomment?id=${item.id}`)
        .get(response => {
          console.log(response)
          postcommentll.value.listEntity.splice(index, 1)
        })
    }

    return {
      newcomment,
      deleteComment,

      loader,
      items,
      postcomment,
      postcommentll,
      commentresponse,
      showMore,

      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
        mdiCommentAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.comment-header {
  position: sticky;
  z-index: 200;
  width: 100%;
  top: 0;
  /*background: white;*/
}
.comment-form {
  position: sticky;
  z-index: 200;
  width: 100%;
  bottom: 0;
  /*background: white;*/
}

</style>
