<template>

  <v-card
    class=" handle bordered"
    dense
  >
    <v-card-title class="pa-0 pb-2 pa-md-3 pa-lg-6">

      <v-spacer></v-spacer>

      <v-btn-toggle dense>
        <v-btn icon class="mr-3"
               x-small
               color="error"
               @click="oburl = null; $emit('remove')"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-progress-linear v-if="loading"
                       v-model="progressionval"
                       :buffer-value="progressionmax"
    ></v-progress-linear>
    <v-img class="ma-auto"
      :width="width"
      :height="height"
      contain
      :src="oburl"
    ></v-img>
    <v-card-actions class="text-center">
      <v-btn class="ma-auto" @click="document.getElementById('fileInputCover').click()" >
        <v-icon>{{icons.mdiImageOutline}}</v-icon> {{label ? label : 'Changer de couverture'}}
      </v-btn>
      <v-file-input  id="fileInputCover"
        label="Ajouter une image"
        dense
        accept=".jpeg, .jpg, .png, .gif"
        outlined
        @change="addimages"
                     class="hidden d-none"
      ></v-file-input>
    </v-card-actions>
  </v-card>

</template>

<script>
import {Drequest} from '@/plugins/Drequest'
import $ from 'jquery'
import {
  mdiAccountGroupOutline, mdiArrowUpDown, mdiChevronLeft,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileImageOutline,
  mdiFolderAlert,
  mdiImageOutline,
  mdiRedo
} from '@mdi/js'

export default {
  name: 'ImageUploadController',
  data() {
    return {
      oburl: '',
      cover: '',
      thisfile: {},
      document: document,
      progressionmax: 0,
      progressionval: 0,
      retrycounter: 0,
      loading: false,
      retry: false,
      icons: {
        mdiClose,
        mdiFolderAlert,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
        mdiRedo,
        mdiImageOutline,
      },
    }
  },
  props:{
    image: String,
    keyform: String,
    url: String,
    label: String,
    width: {
      type:String | Number,
      default(){
        return 300
      }
    },
    height: {
      type:String | Number,
      default(){
        return 300
      }
    },
  },

  /*beforeMount() {
    if (this.image.id) {
      this.thisfile = this.image
    } else {
      this.thisfile = this.image.file
    }
  },*/
  mounted() {

    console.log(this.image)
    if (this.image)
      this.oburl = this.image

  },
  methods: {
    addimages: function (el) {
      console.log(el)
      this.image = (el)
      this.oburl = window.URL.createObjectURL(el)
      this.retry = false
      this.upload()

    },
    oncomplete(response) {

      this.loading = false
      console.log(response)
      this.$emit('uploaded', response)

    },
    upload() {

      console.log(this.image)
      this.loading = true
      this.retry = false
      var form = new FormData()
      form.append(this.keyform, this.image)
      Drequest.api(`${this.url}`)
        .data(form)
        .upload((loaded, total) => {

            this.progressionval = ((loaded / total) * 100)
            this.progressionmax = 100

          },
          this.oncomplete)


      return 0
    },
    remove() {
      if (!confirm('Confirmez la supprission de l\'image')) {
        return
      }

      this.$emit('remove')
      //chaptermanagervue.removeimage(this.thisfile, this.index)
    },
  }, // thisfile.type
}
</script>

<style scoped>

</style>
