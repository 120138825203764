<template>
  <div
    v-if="user_id"
    class="comment-form pa-3 px-0  bg-gradient-darker"
  >
    <template>
    </template>
    <v-list>
      <v-list-item dense class="align-center"
      >
        <user-avatar
          class="mr-3 " :active="false" :user="user" :size="36"></user-avatar>

        <v-list-item-content>
          <v-textarea  class="flex-1 px-0"
                       v-model="message"
                       auto-grow dense
                       rows="1"
                       :append-outer-icon="icons.mdiSend"
                       :loading="loading"
                       :label="replycomment.id ? 'repondre à @'+(replycomment.user.username) : 'Laissez un commentaire ..'"
                       type="text"
                       @click:append-outer="onComment"
                       @click:prepend="changeIcon"
          ></v-textarea>
<!--          <v-list-item-subtitle  v-if="replycomment.id" class="d-flex justify-space-between align-center">
            <v-chip class="mb-3" @click="$emit('cancelReply')">
              {{replycomment.user.username}} <v-icon>{{icons.mdiClose}}</v-icon>
            </v-chip>
          </v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
  <div v-else>
    Vous devez être
    <v-btn x-small color="primary" text @click="$emit('initlogin')">connecté</v-btn>
    pour poster un commentaire :D
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiClose,
  mdiSend,
  mdiCloseCircle,
  mdiEmoticon,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Duration from "@/components/Duration.vue";
import KolaToggleComponent from "@/components/KolaToggleComponent.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {UserAvatar, KolaToggleComponent, Duration},
  props: {
    post_id: Number,
    user: {
      type: Object,
      default: () => {

      },
    },
    answerto: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const message = ref('')
    const postcomment = ref({})
    const user_id = localStorage.getItem('user_id')

    const replycomment = computed(() => props.answerto)
    // message.value = '@'+replycomment.value.user.username
    watch(replycomment, (currentValue, oldValue) => {
      console.log(oldValue)
      if (currentValue.id) {
        message.value = '@' + currentValue.user.username + ' '
      } else {
        message.value = ''
      }
    })
    watch(message, (currentValue, oldValue) => {
      console.log(oldValue)
      if (!currentValue) {
        replycomment.value = {}
      }
    })

    const onComment = () => {
      if (!message.value) return 0

      loading.value = true
      Drequest.createComment(props.post_id, {
        comment: message.value,
        parent_id: replycomment.value.id,
      })
        .raw(response => {
          loading.value = false
          console.log(response)
          if (response.success) {
            clearMessage()
            emit('sent', response)
          }
        })
        .fail(e => {
          loading.value = false
          alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
          console.log(e)
        })
    }

    const clearMessage = () => {
      message.value = ''
    }

    const changeIcon = () => {
      message.value = ''
    }

    return {
      onComment,
      clearMessage,
      changeIcon,

      replycomment,
      user_id,
      loading,
      message,
      postcomment,

      icons: {
        mdiSend,
        mdiClose,
        mdiCloseCircle,
        mdiEmoticon,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.v-text-field__details {
  display: none !important;
}
</style>
