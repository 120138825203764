<template>
  <!--
    <v-app>

      <v-layout>
        <v-app-bar
          app
          color="white"
          elevation="0"
        >
          <div class="boxed-container w-full">
            <div class="d-flex align-center mx-lg-6">
          <v-app-bar-nav-icon
            @click="$router.back()"
          >
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title  >
            retour
          </v-toolbar-title>

          <v-spacer></v-spacer>
            </div>
          </div>
        </v-app-bar>
  -->

  <v-main>
    <v-card max-width="1200"
            class="overflow-hidden ma-auto"
    >
      <v-responsive>
        <router-view
          @setTitle="setTitle"
        ></router-view>
      </v-responsive>
    </v-card>
  </v-main>

  <!--    <bottom-navigation></bottom-navigation>
</v-layout>
</v-app>-->
</template>

<script>
import {ref} from '@vue/composition-api'
import {
  mdiMagnify,
  mdiBellOutline,
  mdiGithub,
  mdiAccountOutline,
  mdiTwitter,
  mdiGoogle,
  mdiEyeOutline,
  mdiCogOutline,
  mdiCreation,
  mdiGrid,
  mdiAccountGroupOutline,
  mdiAccessPoint, mdiChevronLeft,
  mdiGamepadCircleOutline, mdiEyeOffOutline,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import PWAPrompt from '@/layouts/components/PWAPrompt'
import FlatScreen from '@/layouts/components/FlatScreen'
import BottomNavigation from '@/layouts/components/BottomNavigation'

export default {
  components: {
    BottomNavigation,
    FlatScreen,
    PWAPrompt,
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    mdiTwitter,
    mdiGithub,
    mdiGoogle,
    mdiEyeOutline,
    mdiCogOutline,
    mdiCreation,
    mdiGrid,
    mdiAccountGroupOutline,
    mdiAccessPoint,
    mdiGamepadCircleOutline,
    mdiEyeOffOutline,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
    }
  },
  methods: {
    setTitle(title, url){
      this.$emit('setTitle', title, url)
    }
  },
  setup() {
    const isDrawerOpen = ref(null)

    const bnav = ref(0)

    return {
      isDrawerOpen,
      bnav,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiAccountOutline,
        mdiTwitter,
        mdiGoogle,
        mdiEyeOutline,
        mdiCogOutline,
        mdiCreation,
        mdiGrid,
        mdiAccountGroupOutline,
        mdiAccessPoint,
        mdiGamepadCircleOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
