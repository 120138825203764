<template>
    <v-card
      :key="oburl"
      class=" handle bordered ma-1 "
      dense max-height="330" style="overflow: hidden"
    >
      <v-card-title class="pa-0 pb-2 pa-md-3 pa-lg-6">
        <v-switch v-if="thisfile.id"
          v-model="cover" dense class="text-sm"
          hide-details
          :value="thisfile.id"
          :true-value="thisfile.id"
          :false-value="''"
          @change="$emit('changeCover', thisfile.id); changeCover()"
          label="Cover"
        ></v-switch>
        <v-spacer></v-spacer>

        <v-btn-toggle v-if="retry" dense>
          <v-btn fab
                 x-small
                 color="warning"
                 @click="upload(); retrycounter = 0"
          >
            <v-icon>{{ icons.mdiRedo }}</v-icon>
          </v-btn>
          <v-btn fab
                 x-small
                 color="warning"
                 @click="$emit('delete')"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle v-if="thisfile.id" dense>
          <v-btn icon class="mr-3"
                 x-small
                 color="error"
                 @click="remove"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-subtitle class="pa-0 pa-md-3 pa-lg-6">
        <b>{{ thisfile.name }}</b><br>
        {{ (thisfile.size / 1024 / 1024).toFixed(2) }} Mo
      </v-card-subtitle>
      <v-progress-linear v-if="loading"
                         v-model="progressionval"
                         :buffer-value="progressionmax"
      ></v-progress-linear>
      <img @click="$emit('preview', index)"
        class="mr-3"
        width="100%"
        :src="oburl"
      >
    </v-card>

</template>

<script>
import {Drequest} from '@/plugins/Drequest'
import $ from 'jquery'
import {
  mdiAccountGroupOutline, mdiArrowUpDown, mdiChevronLeft,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileImageOutline,
  mdiFolderAlert,
  mdiRedo
} from '@mdi/js'

export default {
  name: 'chapterImageFormController',
  data() {
    return {
      oburl: '',
      cover: '',
      thisfile: {},
      progressionmax: 0,
      progressionval: 0,
      retrycounter: 0,
      loading: false,
      retry: false,
      icons: {
        mdiClose,
        mdiFolderAlert,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
        mdiRedo,
      },
    }
  },
  props: ['image', 'chapter', 'index'],
  beforeMount() {
    if (this.image.id) {
      this.thisfile = this.image
    } else {
      this.thisfile = this.image.file
    }
  },
  mounted() {
    // if (!idprod) {
    //     this.upload();
    // }
    console.log(this.image)
    this.cover = this.chapter.cover
    this.addimages()

  },
  methods: {
    addimages: function () {
      if (this.thisfile.id) {
        this.oburl = this.image.oburl
        return 0
      }
      this.oburl = window.URL.createObjectURL(this.image.file)
      this.retry = false
      this.upload()

    },
    oncomplete(response) {

      this.loading = false
      console.log(response)
      if (!response.success) {
        this.retrycounter++
        /*if(this.retrycounter < 3){
          // alert(this.retrycounter)
          this.upload()
          return  null
        }
        this.retry = true*/
        return null
      }
      this.thisfile.id = response.scanpage.id
      this.image.id = response.scanpage.id
      /* this.thisfile = {
        ...this.thisfile,
        ...response.chapterimage,
      } */
      //if (!this.chapter.id) {
      //this.uploaded()
      this.$emit('uploaded', this.thisfile, this.index)
      //}

    },
    upload() {
      var el = this.$el
      console.log(this.image)
      this.loading = true
      this.retry = false
      var form = new FormData()
      form.append('name', this.image.file.name)
      form.append('image', this.image.file)
      /*if (this.chapter.nbpage)
        form.append('position', this.chapter.nbpage + this.index)
      else*/
        form.append('position', this.index)
      Drequest.api(`chapterimage.upload?chapter_id=${this.chapter.id}`)
        .data(form)
        .upload((loaded, total) => {

            this.progressionval = ((loaded / total) * 100)
            this.progressionmax = 100

          },
          this.oncomplete)


      return 0
    },
    remove() {
      if (!confirm('Confirmez la supprission de l\'image')) {
        return
      }

      this.$emit('removeimage', this.thisfile, this.index)
      //chaptermanagervue.removeimage(this.thisfile, this.index)
    },
    changeCover() {

      Drequest.api(`update.chapter?id=${this.chapter.id}`)
        .data({
          chapter: {
            cover: this.thisfile.id
          }
        })
        .raw((response) => {
          console.log(response)
          this.$emit('changeCover', this.thisfile.id, response.chapter)
        })

    }
  }, // thisfile.type
}
</script>

<style scoped>

</style>
