<script setup>

export default {
  props: {
    lastDate: String,
    explicite: {
      type:Boolean,
      default(){
        return false
      },
    },
  },
  data() {
    return {
      readMoreActivated: false
    }
  },
  computed: {
    daysSinceLastDate() {
      const currentDate = new Date()
      const previousDate = new Date(this.lastDate)
      const timeDifference = currentDate.getTime() - previousDate.getTime()
      if (timeDifference > this.$store.state.timeStep.yearStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.yearStep) +' ans'
      if (timeDifference > this.$store.state.timeStep.monthStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.monthStep) +' mois'
      if (timeDifference > this.$store.state.timeStep.weekStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.weekStep) +' sem'
      if (timeDifference > this.$store.state.timeStep.dayStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.dayStep) +' j'
      if (timeDifference > this.$store.state.timeStep.hourStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.hourStep) +' h'
      if (timeDifference > this.$store.state.timeStep.minStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.minStep) +' m'
      if (timeDifference > this.$store.state.timeStep.secStep)
        return Math.floor(timeDifference / this.$store.state.timeStep.secStep) +' s'
     /* const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      if (daysDifference)
        return daysDifference+' j'
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60))
      if (hoursDifference)
        return hoursDifference+' h'
      const minutesDifference = Math.floor(timeDifference / (1000 * 60))
      if (minutesDifference)
        return minutesDifference+' m'
      const secondsDifference = Math.floor(timeDifference / (1000))
      if (secondsDifference)
        return secondsDifference+' s'*/

      return "à l'instant"
    },
  },
}
</script>

<template>
   <span v-if="!explicite" >{{ daysSinceLastDate }}</span>
   <span v-else >IL Y A {{ daysSinceLastDate }}</span>
</template>

<style scoped lang="scss">

</style>
